import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import appReducer from './reducers';

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const initialState = {};
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;
const enhancers = [];
let middlewares = [thunk];

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

export default () => {
  const store = createStore(rootReducer, initialState, composedEnhancers);
  const history = syncHistoryWithStore(createBrowserHistory(), store);
  return { store, history };
};
