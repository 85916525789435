import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default allowedRole => WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        user: JSON.parse(localStorage.getItem('user_admin'))
      };
    }
    render() {
      let roles;
      if (this.state.user !== null) {
        // roles = this.state.user.roles;
        roles = ['ROLE_ADMIN'];
      } else {
        roles = [''];
      }
      if (roles.includes(allowedRole)) {
        return <WrappedComponent {...this.props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/home/',
              state: { error: 'Meta.error.unauthorized' }
            }}
          />
        );
      }
    }
  };
};
