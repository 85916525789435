import Loadable from 'react-loadable';
import LoadingComponent from '../../LoadingComponent';

const Create = Loadable({
  loader: () => import('./Create'),
  loading: LoadingComponent
});
const List = Loadable({
  loader: () => import('./List'),
  loading: LoadingComponent
});
const Update = Loadable({
  loader: () => import('./Update'),
  loading: LoadingComponent
});

export { List, Create, Update };
