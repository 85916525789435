import React from 'react';
import { FormattedMessage } from 'react-intl';

const LoadingComponent = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }
  if (error) {
    // Handle the error state
    return (
      <div>
        <FormattedMessage id={'Meta.asyncLoading.error'} />
      </div>
    );
  }
  return null;
};

export default LoadingComponent;
