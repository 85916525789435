import { combineReducers } from 'redux';

const TREE = {
  menuItems: [
    {
      to: '/home/',
      label: 'Menu.homepage',
      permission: 'ROLE_API',
      icon: 'icon-home',
      sub: []
    },
    {
      to: '/sellers/',
      label: 'Menu.sellers',
      permission: 'ROLE_ADMIN',
      icon: 'icon-basket',
      sub: []
    },
    {
      to: '/products/',
      label: 'Menu.products',
      permission: 'ROLE_API',
      icon: 'icon-diamond',
      sub: []
    }
  ]
};

export const menus = (state = TREE) => {
  return state;
};

export default combineReducers({ menus });
