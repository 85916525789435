import fr from 'react-intl/locale-data/fr';
import LocaleDataFr from './lang/fr.json';
import moment from 'moment';
import 'moment/locale/fr';

import {
  Create as SellersCreate,
  List as SellersList,
  Update as SellersUpdate
} from './components/sellers/';
import {
  Create as ProductsCreate,
  List as ProductsList,
  Update as ProductsUpdate
} from './components/products/';
import {
  Create as ProductsEventsCreate,
  List as ProductsEventsList,
  Update as ProductsEventsUpdate
} from './components/productsEvents/';
import {
  Create as ProductsComponentsCreate,
  List as ProductsComponentsList,
  Update as ProductsComponentsUpdate
} from './components/productsComponents/';
import { Update as ProductsSaleUpdate } from './components/productsSale/';
import { Update as ProductsCustomerUpdate } from './components/productsCustomer/';
import {
  Create as SellersUserCreate,
  List as SellersUserList,
  Update as SellersUserUpdate
} from './components/User/';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import authorization from './authorization';
import Loadable from 'react-loadable';
import LoadingComponent from './LoadingComponent';

addLocaleData([...fr]);

const Security = Loadable({
  loader: () => import('./Security'),
  loading: LoadingComponent
});
const Basepage = Loadable({
  loader: () => import('./Basepage'),
  loading: LoadingComponent
});
const Homepage = Loadable({
  loader: () => import('./components/Homepage/Homepage'),
  loading: LoadingComponent
});

const TransitionedPage = WrappedComponent => {
  const TransitionedComponent = props => (
    <CSSTransition
      transitionAppear={true}
      transitionAppearTimeout={600}
      transitionEnterTimeout={600}
      transitionLeaveTimeout={200}
      transitionName="slide"
      timeout={600}
    >
      <WrappedComponent {...props} />
    </CSSTransition>
  );
  return TransitionedComponent;
};

const Admin = authorization('ROLE_ADMIN');

moment.locale('fr');

class RootContainer extends Component {
  state = {
    currentLocale: 'fr',
    messages: LocaleDataFr
  };

  /*  componentWillUpdate(nextProps) {
    if (
      this.props.auth.authReducer.locale !== nextProps.auth.authReducer.locale
    ) {
      this.loadLocaleMessages(nextProps.auth.authReducer.locale);
    }
  }

  loadLocaleMessages = locale => {
    switch (locale) {
      case 'fr-FR':
        this.setState(
          {
            currentLocale: 'fr',
            messages: LocaleDataFr
          },
          () => {
            moment.locale('fr');
          }
        );
        break;
      default:
        this.setState(
          {
            currentLocale: 'fr',
            messages: LocaleDataFr
          },
          () => {
            moment.locale('fr');
          }
        );
    }
  };*/

  render() {
    const { currentLocale, messages } = this.state;
    const { history } = this.props;

    return (
      <IntlProvider
        locale={currentLocale}
        key={currentLocale}
        messages={messages}
      >
        <Router history={history}>
          <Switch>
            <Route path="/login/" exact strict component={Security} />
            <Basepage>
              <Route
                path="/home/"
                exact
                strict
                component={TransitionedPage(Homepage)}
              />
              <Route path="/" exact render={() => <Redirect to="/home/" />} />
              <Route
                path="/sellers/"
                exact={true}
                strict={true}
                key="sellers"
                component={Admin(TransitionedPage(SellersList))}
              />
              <Route
                path="/sellers/create/"
                exact={true}
                strict={true}
                key="SellersCreate"
                component={Admin(TransitionedPage(SellersCreate))}
              />
              <Route
                path="/sellers/edit/:id"
                exact={true}
                key="SellersUpdate"
                component={Admin(TransitionedPage(SellersUpdate))}
              />
              <Route
                path="/sellers/user/:id"
                exact={true}
                key="SellersUserList"
                component={Admin(TransitionedPage(SellersUserList))}
              />
              <Route
                path="/sellers/user/create/:id"
                exact={true}
                strict={true}
                key="SellersUserCreate"
                component={Admin(TransitionedPage(SellersUserCreate))}
              />
              <Route
                path="/sellers/user/edit/:id"
                exact={true}
                key="SellersUserUpdate"
                component={Admin(TransitionedPage(SellersUserUpdate))}
              />
              <Route
                path="/products/"
                exact={true}
                strict={true}
                key="Products"
                component={TransitionedPage(ProductsList)}
              />
              <Route
                path="/products/create/"
                exact={true}
                strict={true}
                key="ProductsCreate"
                component={Admin(TransitionedPage(ProductsCreate))}
              />
              <Route
                path="/products/edit/:id"
                exact={true}
                key="ProductsUpdate"
                component={Admin(TransitionedPage(ProductsUpdate))}
              />
              <Route
                path="/products/events/:id"
                exact={true}
                strict={true}
                key="ProductsEventsList"
                component={Admin(TransitionedPage(ProductsEventsList))}
              />
              <Route
                path="/products/events/create/:id"
                exact={true}
                strict={true}
                key="ProductsEventsCreate"
                component={Admin(TransitionedPage(ProductsEventsCreate))}
              />
              <Route
                path="/products/events/edit/:id"
                exact={true}
                key="ProductsEventsUpdate"
                component={Admin(TransitionedPage(ProductsEventsUpdate))}
              />
              <Route
                path="/products/components/:id"
                exact={true}
                strict={true}
                key="ProductsComponentsList"
                component={Admin(TransitionedPage(ProductsComponentsList))}
              />
              <Route
                path="/products/components/create/:id"
                exact={true}
                strict={true}
                key="ProductsComponentsCreate"
                component={Admin(TransitionedPage(ProductsComponentsCreate))}
              />
              <Route
                path="/products/components/edit/:id"
                exact={true}
                key="ProductsComponentsUpdate"
                component={Admin(TransitionedPage(ProductsComponentsUpdate))}
              />
              <Route
                path="/products/sale/edit/:id"
                exact={true}
                key="ProductsSaleUpdate"
                component={Admin(TransitionedPage(ProductsSaleUpdate))}
              />
              <Route
                path="/products/customer/edit/:id"
                exact={true}
                key="ProductsCustomerUpdate"
                component={Admin(TransitionedPage(ProductsCustomerUpdate))}
              />
            </Basepage>
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainer);
