import { combineReducers } from 'redux';
import jwt_decode from 'jwt-decode';

const INITIAL_STATE = {
  loading: false,
  token: null,
  user: null,
  locale: 'fr-FR',
  error: false,
  message: '',
  isCredentialsNonExpired: false,
  resetPwd: false
};

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOG_IN_SUCCESS':
      const user = jwt_decode(action.token);
      return {
        ...state,
        loading: false,
        error: false,
        message: '',
        unlock_time: '',
        token: action.token,
        user: JSON.stringify(user),
        isCredentialsNonExpired: user.isCredentialsNonExpired
      };
    case 'LOG_IN_FAILURE':
      const { error } = action;

      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        error: true,
        message: error,
        isCredentialsNonExpired: false
      };
    case 'LOG_IN_LOADING':
      return {
        ...state,
        loading: action.loading
      };
    case 'LOG_RESET':
      const locale = state.locale;
      return {
        ...INITIAL_STATE,
        locale: locale
      };

    case 'LOG_OUT':
      return {
        ...INITIAL_STATE
      };

    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        resetPwd: true
      };
    case 'CHANGE_PASSWORD_FAILURE':
      return {
        ...state,
        message: action.error.message,
        error: true
      };
    case 'CHANGE_PASSWORD_LOADING':
      return {
        ...state,
        loading: action.loading
      };
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        resetPwd: true
      };
    case 'RESET_PASSWORD_FAILURE':
      return {
        ...state,
        loading: false,
        message: action.error.message,
        error: true
      };
    case 'RESET_PASSWORD_LOADING':
      return {
        ...state,
        loading: action.loading
      };
    case 'CHANGE_LOCALE':
      return {
        ...state,
        locale: action.locale
      };
    default:
      return state;
  }
}

export default combineReducers({
  authReducer
});
