import { combineReducers } from 'redux';

export const activeProduct = (state = null, action) => {
  switch (action.type) {
    case 'ACTIVE_PRODUCT_SET':
      return action.data;

    case 'ACTIVE_PRODUCT_RESET':
      return null;

    default:
      return state;
  }
};

export default combineReducers({ activeProduct });
