import { combineReducers } from 'redux';

export const error = (state = null, action) => {
  switch (action.type) {
    case 'USER_LIST_ERROR':
      return action.error;

    case 'USER_LIST_RESET':
      return null;

    default:
      return state;
  }
};

export const loading = (state = false, action) => {
  switch (action.type) {
    case 'USER_LIST_LOADING':
      return action.loading;

    case 'USER_LIST_RESET':
      return false;

    default:
      return state;
  }
};

export const items = (state = [], action) => {
  switch (action.type) {
    case 'USER_LIST_SUCCESS':
      return action.items;

    case 'USER_LIST_RESET':
      return [];

    default:
      return state;
  }
};

export const totalItems = (state = 0, action) => {
  switch (action.type) {
    case 'USER_LIST_SUCCESS':
      return action.totalItems;

    case 'USER_LIST_RESET':
      return 0;

    default:
      return state;
  }
};

export default combineReducers({ error, loading, items, totalItems });
