import { combineReducers } from 'redux';

export const error = (state = null, action) => {
  switch (action.type) {
    case 'PRODUCT_PICTURE_DELETE_ERROR':
      return action.error;

    default:
      return state;
  }
};

export const loading = (state = false, action) => {
  switch (action.type) {
    case 'PRODUCT_PICTURE_DELETE_LOADING':
      return action.loading;

    default:
      return state;
  }
};

export const deleted = (state = null, action) => {
  switch (action.type) {
    case 'PRODUCT_PICTURE_DELETE_SUCCESS':
      return action.deleted;
    case 'PRODUCT_PICTURE_DELETE_RESET':
      return null;
    case 'PRODUCT_PICTURE_DELETE_NOTIFICATION_RESET':
      return null;

    default:
      return state;
  }
};

export default combineReducers({ error, loading, deleted });
