import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import activeProduct from './activeProduct/activeProduct';
import authReducer from './security/auth';
import menus from './menus/menus';
import sellers from './sellers';
import users from './users';
import products from './products';
import productsEvents from './productsEvents';
import productsComponents from './productsComponents';
import productEventPicture from './productEventPicture';
import productPicture from './productPicture';
import productSales from './productSales';
import productCustomers from './productCustomers';
import productEventActors from './productEventActors';

const reducers = combineReducers({
  toastr: toastrReducer,
  routing,
  activeProduct,
  authReducer,
  form,
  menus,
  sellers,
  users,
  products,
  productsEvents,
  productsComponents,
  productEventPicture,
  productPicture,
  productSales,
  productCustomers,
  productEventActors
});

export default reducers;
