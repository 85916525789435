import { combineReducers } from 'redux';

export const retrieveError = (state = null, action) => {
  switch (action.type) {
    case 'PRODUCT_SALE_UPDATE_RETRIEVE_ERROR':
      return action.retrieveError;

    case 'PRODUCT_SALE_UPDATE_RESET':
      return null;

    default:
      return state;
  }
};

export const retrieveLoading = (state = false, action) => {
  switch (action.type) {
    case 'PRODUCT_SALE_UPDATE_RETRIEVE_LOADING':
      return action.retrieveLoading;

    case 'PRODUCT_SALE_UPDATE_RESET':
      return false;

    default:
      return state;
  }
};

export const retrieved = (state = null, action) => {
  switch (action.type) {
    case 'PRODUCT_SALE_UPDATE_RETRIEVE_SUCCESS':
      return action.retrieved;

    case 'PRODUCT_SALE_UPDATE_RESET':
      return null;

    default:
      return state;
  }
};

export const updateError = (state = null, action) => {
  switch (action.type) {
    case 'PRODUCT_SALE_UPDATE_UPDATE_ERROR':
      return action.updateError;

    case 'PRODUCT_SALE_UPDATE_RESET':
      return null;

    default:
      return state;
  }
};

export const updateLoading = (state = false, action) => {
  switch (action.type) {
    case 'PRODUCT_SALE_UPDATE_UPDATE_LOADING':
      return action.updateLoading;

    case 'PRODUCT_SALE_UPDATE_RESET':
      return false;

    default:
      return state;
  }
};

export const updated = (state = null, action) => {
  switch (action.type) {
    case 'PRODUCT_SALE_UPDATE_UPDATE_SUCCESS':
      return action.updated;
    case 'PRODUCT_SALE_UPDATE_RESET':
      return null;
    case 'PRODUCT_SALE_UPDATE_NOTIFICATION_RESET':
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  retrieveError,
  retrieveLoading,
  retrieved,
  updateError,
  updateLoading,
  updated
});
